import React from 'react';
import { Translate as T } from 'react-redux-i18n';
import styles from './Card.module.scss';

export default function Card({ card }: { card: any }) {
  return (
    <div className={styles.card}>
      <section className={styles.top}>
        <div className={styles.icons}>{card.banner && <card.banner />}</div>
      </section>
      <section className={styles.bottom}>
        <div className={styles.title}>
          <T value={card.title} />
        </div>
        <div className={styles.description}>
          <T value={card.description} />
        </div>
      </section>
    </div>
  );
}
