import React from 'react';
import styles from './BannerBase.module.scss';

export default function BannerBase({
  children,
}: {
  children: React.ReactNode;
}) {
  return <div className={styles.banner}>{children}</div>;
}
