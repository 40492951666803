import React from 'react';
import { M } from '@dashboard-experience/mastodon';

export default function CloseBanner({
  handleDismissClick,
}: {
  handleDismissClick: () => void;
}) {
  return (
    <M.Icon
      icon='Close'
      onClick={handleDismissClick}
      style={{
        position: 'absolute',
        right: '24px',
        top: '8px',
      }}
    />
  );
}
