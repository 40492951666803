import React from 'react';
import { uuid4 } from '@sentry/utils';
import Card from './Card/Card';
import styles from './Cards.module.scss';

export default function Cards({ cards }: { cards: any[] }) {
  return (
    <div className={styles.container}>
      {cards.map(card => (
        <Card key={uuid4()} card={card} />
      ))}
    </div>
  );
}
