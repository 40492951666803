import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { Translate as T } from 'react-redux-i18n';
import { useStore, useDispatch } from 'react-redux';
import { useFlagr } from 'api/flagr';
import { PERSONAL_CHECKS_ANALYTICS_EVENTS } from 'lib/analytics';
import { trackAnalyticsEvent } from '../../actions/analytics';
import { FLAGR_KEYS } from '../../constants';
import InfoModal from './InfoModal/InfoModal';
import IconBanner from './Banner/Banners/IconBanner';
import SuccessModal from './SuccessModal/SuccessModal';

const BANNER_DISMISSED_KEY = 'bannerDismissed';
const BANNER_SUBSCRIBED_KEY = 'bannerSubscribed';
const BANNER_VIEWED_KEY = 'bannerViewTracked';

export default function PersonalChecksBanner() {
  const state = useStore().getState();
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const { reports } = state.portal;
  const [dismissed, setDismissed] = useState(() => {
    return localStorage.getItem(BANNER_DISMISSED_KEY) === 'true';
  });
  const [modal, setModal] = useState<null | 'details' | 'success'>(null);

  const { flag, isFetched: flagIsLoaded } = useFlagr({
    context: {
      locationHost: window.location.host,
      applicantId: state.portal.applicantId,
    },
    id: FLAGR_KEYS.personalChecksBannerFlagKey,
    entity: { id: state.portal.applicantId, type: 'applicantId' },
    fallbackVariant: 'off',
  });

  const { flag: ctaTitleFlag } = useFlagr({
    context: {
      locationHost: window.location.host,
      applicantId: state.portal.applicantId,
    },
    id: FLAGR_KEYS.personalChecksCtaTitleFlagKey,
    entity: { id: state.portal.applicantId, type: 'applicantId' },
    fallbackVariant: 'off',
  });

  const { flag: infoPageFlag } = useFlagr({
    context: {
      locationHost: window.location.host,
      applicantId: state.portal.applicantId,
    },
    id: FLAGR_KEYS.personalChecksInfoPageFlagKey,
    entity: { id: state.portal.applicantId, type: 'applicantId' },
    fallbackVariant: 'off',
  });

  let pageType;

  if (pathname === '/reports') {
    pageType = 'reportsPage';
  } else if (/^\/reports\/[^/]+$/.test(pathname)) {
    pageType = 'singleReportPage';
  } else {
    pageType = 'other';
  }

  const {
    variantKey: personalChecksBannerKey,
    variantAttachment: personalChecksBannerAttachments,
    variantID: personalChecksBannerVariantId,
  } = flag;
  const { variantKey: ctaTitleKey } = ctaTitleFlag;
  const { variantKey: infoPageKey } = infoPageFlag;

  // once flag is loaded, check the blockedAccountIds against report
  // account_ids, if there then don't display the banner
  useEffect(() => {
    if (
      flagIsLoaded &&
      personalChecksBannerKey !== 'off' &&
      localStorage.getItem(BANNER_DISMISSED_KEY) !== 'true'
    ) {
      const blockedAccountIds =
        personalChecksBannerAttachments?.blockedAccountIds;

      const allowListAccountIds =
        personalChecksBannerAttachments?.allowListAccountIds;

      if (blockedAccountIds && reports) {
        const blockedAccountIdsSet = new Set(blockedAccountIds);

        const hasBlockedReport = reports.some((report: any) => {
          return blockedAccountIdsSet.has(report.accountId);
        });

        // If there's a blocked account, mark as dismissed
        if (hasBlockedReport) {
          setDismissed(true);
          return;
        }
      }
      // Check if the user has already subscribed to the banner
      const bannerSubscribed = localStorage.getItem(BANNER_SUBSCRIBED_KEY);

      // If the user has already subscribed, dismiss the banner
      if (bannerSubscribed) {
        setDismissed(true);
        return;
      }

      // if there are white listed accounts, check if any of the reports are in the whitelist and show the banner
      if (allowListAccountIds && allowListAccountIds.length > 0) {
        const allowListAccountIdsSet = new Set(allowListAccountIds);

        const hasAllowListedReport = reports.some((report: any) =>
          allowListAccountIdsSet.has(report.accountId),
        );

        // If there are no whitelisted reports, dismiss the banner
        if (!hasAllowListedReport) {
          setDismissed(true);
          return;
        }
      }
      // Check local storage for a token to prevent multiple tracking events
      const bannerViewTracked = localStorage.getItem(BANNER_VIEWED_KEY);

      if (!bannerViewTracked) {
        // If no token exists, track the event and set the token
        dispatch(
          trackAnalyticsEvent(
            PERSONAL_CHECKS_ANALYTICS_EVENTS.PERSONAL_CHECKS_BANNER_VIEWED,
            {
              variantId: personalChecksBannerVariantId,
              variantKey: personalChecksBannerKey,
              ctaTitleKey,
              pageType,
              infoPageKey,
            },
          ),
        );

        localStorage.setItem(BANNER_VIEWED_KEY, 'true');
      }

      // Set dismissed to false if no blocked accounts were matched
      setDismissed(false);
    }
  }, [
    ctaTitleKey,
    flagIsLoaded,
    personalChecksBannerKey,
    personalChecksBannerAttachments,
    personalChecksBannerVariantId,
    reports,
    dispatch,
    pageType,
    infoPageKey,
  ]);

  if (dismissed || !flagIsLoaded || personalChecksBannerKey === 'off') {
    return null;
  }

  const handleDetailsClick = () => {
    dispatch(
      trackAnalyticsEvent(
        PERSONAL_CHECKS_ANALYTICS_EVENTS.PERSONAL_CHECKS_LEARN_MORE_CLICKED,
        {
          variantId: personalChecksBannerVariantId,
          variantKey: personalChecksBannerKey,
          ctaTitleKey,
          pageType,
          infoPageKey,
        },
      ),
    );

    setModal('details');
  };

  const handleCloseClick = () => {
    dispatch(
      trackAnalyticsEvent(
        PERSONAL_CHECKS_ANALYTICS_EVENTS.PERSONAL_CHECKS_CLOSE_CLICKED,
        {
          variantId: personalChecksBannerVariantId,
          variantKey: personalChecksBannerKey,
          ctaTitleKey,
          pageType,
        },
      ),
    );

    setModal(null);
  };

  const handleSubscribeClick = () => {
    dispatch(
      trackAnalyticsEvent(
        PERSONAL_CHECKS_ANALYTICS_EVENTS.PERSONAL_CHECKS_SUBSCRIBE_CLICKED,
        {
          variantId: personalChecksBannerVariantId,
          variantKey: personalChecksBannerKey,
          ctaTitleKey,
          pageType,
        },
      ),
    );

    // show the success modal page
    setModal('success');
    // set localStorage item to prevent showing the banner
    localStorage.setItem(BANNER_SUBSCRIBED_KEY, 'true');
  };

  const handleDismissClick = () => {
    dispatch(
      trackAnalyticsEvent(
        PERSONAL_CHECKS_ANALYTICS_EVENTS.PERSONAL_CHECKS_DISMISS_CLICKED,
        {
          variantId: personalChecksBannerVariantId,
          variantKey: personalChecksBannerKey,
          ctaTitleKey,
          pageType,
        },
      ),
    );

    // set localStorage item to prevent showing the banner
    localStorage.setItem(BANNER_DISMISSED_KEY, 'true');

    // dismiss the banner
    setDismissed(true);
  };

  let bannerTitle;

  switch (ctaTitleKey) {
    case 'hiredFaster':
      bannerTitle = (
        <T
          value='components.PersonalChecks.banner.title.hiredFaster'
          dangerousHTML
        />
      );
      break;
    default:
      bannerTitle = (
        <T
          value='components.PersonalChecks.banner.title.preApproved'
          dangerousHTML
        />
      );
      break;
  }

  return (
    <>
      <IconBanner
        bannerTitle={bannerTitle}
        handleDetailsClick={handleDetailsClick}
        personalChecksBannerKey={personalChecksBannerKey}
        handleDismissClick={handleDismissClick}
      />
      {/* modal with details */}
      {modal === 'details' && (
        <div id='personal-checks-modal'>
          <InfoModal
            handleSubscribeClick={handleSubscribeClick}
            handleCloseClick={handleCloseClick}
            bannerTitle={bannerTitle}
            infoPageKey={infoPageKey}
          />
        </div>
      )}
      {modal === 'success' && (
        <div id='personal-checks-modal'>
          <SuccessModal handleCloseClick={handleCloseClick} />
        </div>
      )}
    </>
  );
}
