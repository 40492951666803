import React from 'react';
import { Translate as T } from 'react-redux-i18n';
import { M } from '@dashboard-experience/mastodon';
import PersonalChecksModalHeader from '../InfoModal/Header/Header';
import './SuccessModal.scss';

export default function SuccessModal({
  handleCloseClick,
}: {
  handleCloseClick: () => void;
}) {
  return (
    <>
      <PersonalChecksModalHeader handleCloseClick={handleCloseClick} />
      <div id='personal-checks-modal-success-container'>
        <div id='personal-checks-modal-success'>
          <div id='personal-checks-modal-success-content'>
            <M.Icon
              icon='CheckmarkFilled'
              style={{
                fill: 'var(--aqua-500, rgba(0, 156, 184, 1))',
                height: 32,
                width: 32,
              }}
            />
            <p id='personal-checks-modal-success-title'>
              <T value='components.PersonalChecks.modal.success.title' />
            </p>
            <p id='personal-checks-modal-success-description'>
              <T
                value='components.PersonalChecks.modal.success.description'
                dangerousHTML
              />
            </p>
          </div>
        </div>
      </div>
    </>
  );
}
