import { findEnvVar } from '@dashboard-experience/utils';
import { camelCase, isArray, isObject, transform } from 'lodash';
// Mocks
import clearinghouse from './fixtures/clearinghouse.json';
import consider from './fixtures/domestic-consider.json';
import drugScheduled from './fixtures/domestic-drug-schedule.json';
import drugClear from './fixtures/domestic-drug-clear.json';
import drugConsider from './fixtures/domestic-drug-consider.json';
import drugAlcoholClearinghouse from './fixtures/drug_alcohol_clearinghouse-clear.json';
import occHealthClear from './fixtures/domestic-occ-health-clear.json';
import occHealthConsider from './fixtures/domestic-occ-health-consider.json';
import drugClearOccHealthCanceled from './fixtures/domestic-drug-clear-occ-health-canceled.json';
import occHealthClearWithCanceledExam from './fixtures/domestic-occ-health-clear-with-canceled-exam.json';
import expungement from './fixtures/domestic-expungement.json';
import approved from './fixtures/expungements/approved.json';
import submitted from './fixtures/expungements/submitted.json';
import eligible from './fixtures/expungements/eligible.json';
import potential from './fixtures/expungements/potential.json';
import international_consider from './fixtures/international-consider.json';
import international_clear from './fixtures/international-clear.json';
import ssnTraceReportable from './fixtures/domestic-ssn-trace-consider.json';

// Convert out JSON objects keys that use snake_case to camelCase
// eslint-disable-next-line max-len
// https://stackoverflow.com/questions/59769649/recursively-convert-an-object-fields-from-snake-case-to-camelcase#comment123909399_59771233
const camelize = (obj: Record<string, unknown>) =>
  transform(
    obj,
    (result: Record<string, unknown>, value: unknown, key: string, target) => {
      const camelKey = isArray(target) ? key : camelCase(key);
      result[camelKey] = isObject(value)
        ? camelize(value as Record<string, unknown>)
        : value;
    },
  );

const MOCK_REPORTS = {
  clearinghouse: { report: camelize(clearinghouse), expungements: {} },
  consider: { report: camelize(consider), expungements: {} },
  drugScheduled: { report: camelize(drugScheduled), expungements: {} },
  drugClear: { report: camelize(drugClear), expungements: {} },
  drugConsider: { report: camelize(drugConsider), expungements: {} },
  drugClearOccHealthCanceled: {
    report: camelize(drugClearOccHealthCanceled),
    expungements: {},
  },
  drugAlcoholClearinghouse: {
    report: camelize(drugAlcoholClearinghouse),
    expungements: {},
  },
  occHealthClear: { report: camelize(occHealthClear), expungements: {} },
  occHealthConsider: { report: camelize(occHealthConsider), expungements: {} },
  occHealthClearWithCanceledExam: {
    report: camelize(occHealthClearWithCanceledExam),
    expungements: {},
  },
  expungement_approved: {
    report: camelize(expungement),
    expungements: camelize(approved),
  },
  expungement_submitted: {
    report: camelize(expungement),
    expungements: camelize(submitted),
  },
  expungement_eligible: {
    report: camelize(expungement),
    expungements: camelize(eligible),
  },
  expungement_potential: {
    report: camelize(expungement),
    expungements: camelize(potential),
  },
  international_consider: {
    report: camelize(international_consider),
    expungements: {},
  },
  international_clear: {
    report: camelize(international_clear),
    expungements: {},
  },
  ssnTraceReportable: {
    report: camelize(ssnTraceReportable),
    expungements: {},
  },
};

export const API_STATUS_PROXY_URL = findEnvVar('API_STATUS_PROXY_URL');
const AMPLITUDE_API_KEY = findEnvVar('AMPLITUDE_API_KEY');
const AMPLITUDE_ENABLED = findEnvVar('AMPLITUDE_ENABLED')
  ? JSON.parse(findEnvVar('AMPLITUDE_ENABLED'))
  : false;
const CHECKR_AMS_BASE_URL = findEnvVar('CHECKR_AMS_BASE_URL');
const CHECKR_API_BASE = findEnvVar('CHECKR_API_BASE');
const CHECKR_APPLICANT_FAQ_PAGE =
  'https://help.checkr.com/hc/en-us/categories/360002478633-Candidates';
const CHECKR_APPLICANTS_BASE_URL = findEnvVar('CHECKR_APPLICANTS_BASE_URL');
const CHECKR_APPLICANTS_US_BASE_URL = findEnvVar(
  'CHECKR_APPLICANTS_US_BASE_URL',
);
const CHECKR_APPLICANTS_EU_BASE_URL = findEnvVar(
  'CHECKR_APPLICANTS_EU_BASE_URL',
);
const CHECKR_REGION = findEnvVar('CHECKR_REGION');
const CHECKR_APPLICANT_PORTAL = findEnvVar('CHECKR_APPLICANTS_BASE_URL');
const CHECKR_APPLY_BASE_URL = findEnvVar('CHECKR_APPLY_BASE');
const CHECKR_EXPUNGEMENTS_API_BASE = findEnvVar('CHECKR_EXPUNGEMENTS_API_BASE');
const CHECKR_EXPUNGEMENTS_PORTAL_BASE = findEnvVar(
  'CHECKR_EXPUNGEMENTS_PORTAL_BASE',
);
const CHECKR_VERIFICATIONS_API_BASE = findEnvVar(
  'CHECKR_VERIFICATIONS_API_BASE',
);
const CHECKR_BFF_API_BASE = findEnvVar('CHECKR_BFF_API_BASE');
const CANDIDATE_STORY_FORM_NAME = 'candidate_stories';
const DISPUTE_FORM_NAME = 'disputes';
const ENV = findEnvVar('ENV');
/* eslint-disable no-underscore-dangle */
const IS_BOT =
  (window as any)._DATADOG_SYNTHETICS_BROWSER === 'true' ||
  window.env?.req?.['x-datadog-origin'] === 'synthetics' ||
  (typeof window.env?.req?.['user-agent'] !== 'undefined' &&
    window.env?.req?.['user-agent'].match(/DatadogSynthetics$/) !== null);
const IS_PRODUCTION = ENV === 'production';
const FILE_STACK_API_KEY = findEnvVar('FILE_STACK_API_KEY');
const RECAPTCHA_SITE_KEY = IS_BOT
  ? findEnvVar('RECAPTCHA_SITE_TEST_KEY')
  : findEnvVar('RECAPTCHA_SITE_KEY');
const SENTRY_DSN = findEnvVar('SENTRY_DSN');
const SENTRY_RELEASE = findEnvVar('SENTRY_RELEASE');
const VERIFICATION_FORM_NAME = 'verification';
const INSTANT_EXCEPTIONS_FORM_NAME = 'instantExceptions';

const CUSTOM_OBJECT_NAMES = ['ssn_check'];
const SSN_CHECK_FAILURE = 'ssn_check_failure';

const CANDIDATE_STORY_SECTIONS = [
  'County Searches',
  'State Search',
  'Federal District Searches',
  'Federal Search',
];

type AutoDisputeScreenings =
  | 'County Searches'
  | 'Municipal Searches'
  | 'State Search';

const AUTO_DISPUTE_SCREENINGS: AutoDisputeScreenings[] = [
  'County Searches',
  'Municipal Searches',
  'State Search',
];

const CANS_STEP_SELECT = 'select';
const CANS_STEP_PROVIDE = 'provide';
const CANS_STEP_REVIEW = 'review';
const MAX_DOCUMENTS = findEnvVar('MAX_DOCUMENTS');

const SESSION_STORAGE_CANDIDATE_IDS_KEY = 'candidate_ids';
const SESSION_STORAGE_CONTACT_EMAIL_KEY = 'contact_email';
const SESSION_STORAGE_CONTACT_NAME_KEY = 'contact_name';

const SESSION_STORAGE_JWT_KEY = 'candidate_jwt';

const DISPUTE_SECTIONS = {
  ssnTrace: { id: 'dispute_ssn_trace', title: 'SSN Verification' },
  evictionSearch: { id: 'dispute_eviction_search', title: 'Civil Search' },
  sexOffenderSearch: {
    id: 'dispute_sex_offender_search',
    title: 'Sex Offender Search',
  },
  terroristWatchlistSearch: {
    id: 'dispute_terrorist_watchlist_search',
    title: 'Global Watchlist Search',
  },
  healthcareSanctionsSearches: {
    id: 'healthcare_sanctions_searches',
    title: 'Healthcare Sanctions Searches',
  },
  internationalCriminalSearches: {
    id: 'dispute_international_criminal_search',
    title: 'International Searches',
  },
  internationalCriminalSearchesV2: {
    id: 'dispute_international_criminal_search',
    title: 'International Criminal Searches',
  },
  internationalCanadaBcEnhancedCriminalSearch: {
    id: 'dispute_international_canada_bc_enhanced_criminal_search',
    title: 'International Canada BC Enhanced Criminal Search',
  },
  internationalGlobalWatchlistSearch: {
    id: 'dispute_international_global_watchlist_search',
    title: 'International Global Watchlist Search',
  },
  internationalEducationVerification: {
    id: 'dispute_international_education_verification',
    title: 'International Education Verification',
  },
  internationalEmploymentVerification: {
    id: 'dispute_international_employment_verification',
    title: 'International Employment Verification',
  },
  internationalAdverseMediaSearches: {
    id: 'dispute_international_adverse_media_search',
    title: 'International Adverse Media Searches',
  },
  internationalIdentityDocumentValidation: {
    id: 'dispute_international_identity_document_validation',
    title: 'International Identity Document Validation',
  },
  internationalMotorVehicleReports: {
    id: 'dispute_international_motor_vehicle_report',
    title: 'International Motor Vehicle Report',
  },
  nationalCriminalSearch: {
    id: 'dispute_national_criminal_search',
    title: 'National Search',
  },
  stateCriminalSearches: {
    id: 'dispute_state_criminal_search',
    title: 'State Search',
  },
  pointerStateCriminalSearches: {
    id: 'pointer_dispute_state_criminal_search',
    title: 'Pointer State Search',
  },
  federalDistrictCriminalSearches: {
    id: 'dispute_federal_district_criminal_search',
    title: 'Federal District Searches',
  },
  federalDistrictCivilSearches: {
    id: 'dispute_federal_district_civil_search',
    title: 'Federal District Civil Searches',
  },
  countyCriminalSearches: {
    id: 'dispute_county_criminal_search',
    title: 'County Searches',
  },
  municipalCriminalSearches: {
    id: 'dispute_municipal_criminal_search',
    title: 'Municipal Searches',
  },
  countyCivilSearches: {
    id: 'dispute_county_civil_search',
    title: 'County Civil Searches',
  },
  childAbuseSearches: {
    id: 'dispute_child_abuse_search',
    title: 'Child Abuse Searches',
  },
  arrestSearch: { id: 'dispute_arrest_search', title: 'Arrest Search' },
  motorVehicleReport: {
    id: 'dispute_motor_vehicle_report',
    title: 'Motor Vehicle Report',
  },
  employmentVerification: {
    id: 'dispute_employment_verification',
    title: 'Employment Verification',
  },
  educationVerification: {
    id: 'dispute_education_verification',
    title: 'Education Verification',
  },
  personalReferenceVerifications: {
    id: 'dispute_personal_reference_verification',
    title: 'Personal Reference Verifications',
  },
  professionalReferenceVerifications: {
    id: 'dispute_professional_reference_verification',
    title: 'Professional Reference Verifications',
  },
  facisSearch: { id: 'dispute_facis_search', title: 'FACIS' },
  creditReport: { id: 'dispute_credit_report', title: 'Credit Report' },
  drugScreening: { id: 'dispute_drug_screening', title: 'Drug Screening' },
  professionalLicenseVerifications: {
    id: 'professional_license_verification',
    title: 'Professional License Verification',
  },
  clearinghouseSearch: {
    id: 'clearinghouse_report',
    title: 'Clearinghouse Report',
  },
};

const HELP_CENTER_LINKS = {
  candidatesHelpCenter:
    'https://help.checkr.com/hc/en-us/categories/360002478633-Candidates?utm_source=candidate_portal&utm_medium=referral&utm_content=Help_Center_Header',
  contactUs:
    'https://help.checkr.com/hc/en-us/requests/new?ticket_form_id=360004159513',
};

const MS_IN_A_DAY = 86400000;
const SECONDS_IN_A_DAY = 86400;

// These constants are also enforced by the monolith
const CANDIDATES_DOCUMENTS_SIZE_MAX_BYTES = 30000000;
const CANDIDATES_DOCUMENTS_SIZE_MIN_BYTES = 1000;

const FLAGR_KEYS = {
  /**
   * @see [Flag: 766]{@link https://flagr-ui.checkrhq.net/#/flags/766}
   */
  newSsnResolutionFlow: 'kbrzvqoyvsgepvrxi',
  useBFF: 'k73oy6ug8yfwpi6ti', // https://flagr-ui.checkrhq.net/#/flags/814,
  showCountyDelayMessage: 'county-delay-candidate-portal', // https://flagr-ui.checkrhq.net/#/flags/1160
  instantExceptionsEnabled: 'kc24s5orrmzy6uvv3', // https://flagr-ui.checkrhq.net/#/flags/1186
  personalChecksBannerFlagKey: 'personal_checks_candidate_portal_banner',
  personalChecksCtaTitleFlagKey: 'personal_checks_cta_title_ab',
  personalChecksInfoPageFlagKey: 'personal_checks_information_page',
};

const UNMASK_DOB_CONTEXT_TEST = Object.freeze({
  geo_id: '984f0795b2ca2dedb7fa3782',
  country: 'CA',
});
const UNMASK_DOB_CONTEXT_LYFT = Object.freeze({
  geo_id: 'a8a73903c6f9d8179aed2349',
  country: 'CA',
});

const ADA_JS_SUPPORT_SCRIPT = 'https://static.ada.support/embed2.js';
const ADA_SCRIPT_ID = '__ada';
const ADA_CONTROL_ID = 'ada-entry';
const FLAGR_URL = findEnvVar('FLAGR_URL');
const CHECKR_PDF_UTILS_URL = findEnvVar('CHECKR_PDF_UTILS_URL');
const ACCOUNT_URI_COOKIE_NAME = 'checkr_account_uri';
const MAX_ALLOWED_UPLOAD_DOCS_QTY = 10;
const STANDARD_ALLOWED_UPLOAD_FILETYPES = ['image/*', '.pdf'];

const CHARGE_ATTRS = Object.freeze({
  // attribute from api,  human readable for display
  arrestDate: 'Arrest Date',
  charge: 'Charge',
  chargeDate: 'Charge Date',
  chargeType: 'Charge Type',
  convictionDate: 'Conviction Date',
  court: 'Court',
  defendant: 'Defendant',
  deposition: 'Deposition',
  depositionDate: 'Deposition Date',
  disposition: 'Disposition',
  dispositionDate: 'Disposition Date',
  jailTime: 'Sentenced To Jail Time', // changed due to litigation risk
  nextCourtDate: 'Next Court Date',
  offenseDate: 'Offense Date',
  plaintiff: 'Plaintiff',
  plea: 'Plea',
  prisonTime: 'Sentenced To Prison Time', // changed due to litigation risk
  probationStatus: 'Probation Status',
  probationTime: 'Sentenced To Probation Time', // changed due to litigation risk
  releaseDate: 'Release Date',
  restitution: 'Sentenced To Restitution', // changed due to litigation risk
  sentence: 'Other Sentencing Info', // changed due to ECRQ-90
  sentenceDate: 'Sentence Date',
});

const CANDIDATE_STORY_MAX_CHARACTER_LIMIT = 65535;

const MVR_MATRIX_TYPES = ['mvr_consent'];

const MVR_DOC_TYPES_WITH_ONE_FILE_LIMIT = [
  'driver_license',
  'driver_license_history',
];

const PATHS = {
  EMAIL_AUTH: '/authenticate-candidate',
  REPORTS_OVERVIEW: '/reports',
  INTERNATIONAL: '/international',
  INTERNATIONAL_REPORTS_OVERVIEW: '/international/reports',
};

const BACKEND_FLAG_PATHS = {
  CANDIDATES_ID: '/candidates/id',
  CANDIDATE_STORIES: '/candidate_stories',
  COMMENTS: '/comments',
  DELETE_FILE: '/delete_file',
  DELETE_PII: '/privacy/delete-request/candidate',
  DISPUTE_INTAKE: '/dispute_intake',
  FULL_FILE_DISCLOSURE: '/privacy/full-file-disclosure-request/candidate',
  PREVIOUSLY_DISPUTED: '/reports/id/recent_finalized_dispute_date',
  REPORTS_CANDIDATES_AUTH: '/reports/candidates/auth',
  REPORTS_ID: '/reports/id',
  REPORTS_SEARCH_CANDIDATE: '/reports/search/candidate',
  SSN_CHECKS_ID: '/ssn_checks/id',
  TOS: '/applicant/id/terms_of_service',
  V2_VERIFICATIONS_ID: '/v2/verifications/id',
  VERIFICATIONS_ID: '/verifications/id',
};

const MVR_INCIDENT_FIELDS = [
  'description',
  'accidentDate',
  'issuedDate',
  'startDate',
  'endDate',
  'date',
  'city',
  'disposition',
  'dispositionDate',
  'points',
  'state',
  'stateCode',
];

const MVR_MEDICAL_CERTIFICATE_FIELDS = [
  'status',
  'selfCertification',
  'issuedDate',
  'expirationDate',
  'examinerFullName',
  'examinerLicenseNumber',
  'examinerLicenseState',
  'examinerRegistrationNumber',
  'examinerPhoneNumber',
];

const INTERNATIONAL_TEST_ACCOUNT = 'international_test';

const US_TERRITORIES = [
  'AS', // American Samoa
  'GU', // Guam
  'MP', // Northern Mariana Islands
  'PR', // Puerto Rico
  'US', // United States
  'VI', // US Virgin Islands
];

const MAX_FILE_SIZE = 31457280; // 30MB

export {
  CANDIDATE_STORY_FORM_NAME,
  CANDIDATE_STORY_MAX_CHARACTER_LIMIT,
  CANDIDATE_STORY_SECTIONS,
  CANDIDATES_DOCUMENTS_SIZE_MAX_BYTES,
  CANDIDATES_DOCUMENTS_SIZE_MIN_BYTES,
  CANS_STEP_PROVIDE,
  CANS_STEP_REVIEW,
  CANS_STEP_SELECT,
  CHECKR_AMS_BASE_URL,
  CHECKR_API_BASE,
  CHECKR_APPLICANT_FAQ_PAGE,
  CHECKR_APPLICANT_PORTAL,
  CHECKR_APPLICANTS_BASE_URL,
  CHECKR_APPLICANTS_EU_BASE_URL,
  CHECKR_APPLICANTS_US_BASE_URL,
  CHECKR_REGION,
  CHECKR_APPLY_BASE_URL,
  CHECKR_EXPUNGEMENTS_API_BASE,
  CHECKR_EXPUNGEMENTS_PORTAL_BASE,
  CHECKR_VERIFICATIONS_API_BASE,
  CHECKR_BFF_API_BASE,
  CUSTOM_OBJECT_NAMES,
  UNMASK_DOB_CONTEXT_TEST,
  UNMASK_DOB_CONTEXT_LYFT,
  DISPUTE_FORM_NAME,
  DISPUTE_SECTIONS,
  ENV,
  FILE_STACK_API_KEY,
  HELP_CENTER_LINKS,
  IS_PRODUCTION,
  IS_BOT,
  INTERNATIONAL_TEST_ACCOUNT,
  MAX_DOCUMENTS,
  MAX_FILE_SIZE,
  MOCK_REPORTS,
  MS_IN_A_DAY,
  RECAPTCHA_SITE_KEY,
  SECONDS_IN_A_DAY,
  SENTRY_DSN,
  SENTRY_RELEASE,
  SESSION_STORAGE_CANDIDATE_IDS_KEY,
  SESSION_STORAGE_CONTACT_EMAIL_KEY,
  SESSION_STORAGE_CONTACT_NAME_KEY,
  SESSION_STORAGE_JWT_KEY,
  SSN_CHECK_FAILURE,
  VERIFICATION_FORM_NAME,
  INSTANT_EXCEPTIONS_FORM_NAME,
  MAX_ALLOWED_UPLOAD_DOCS_QTY,
  STANDARD_ALLOWED_UPLOAD_FILETYPES,
  FLAGR_URL,
  CHECKR_PDF_UTILS_URL,
  ACCOUNT_URI_COOKIE_NAME,
  CHARGE_ATTRS,
  FLAGR_KEYS,
  AMPLITUDE_API_KEY,
  AMPLITUDE_ENABLED,
  MVR_MATRIX_TYPES,
  MVR_DOC_TYPES_WITH_ONE_FILE_LIMIT,
  PATHS,
  BACKEND_FLAG_PATHS,
  ADA_JS_SUPPORT_SCRIPT,
  ADA_SCRIPT_ID,
  ADA_CONTROL_ID,
  MVR_INCIDENT_FIELDS,
  MVR_MEDICAL_CERTIFICATE_FIELDS,
  US_TERRITORIES,
  AUTO_DISPUTE_SCREENINGS,
};
