import React from 'react';
import { M } from '@dashboard-experience/mastodon';
import { Translate as T } from 'react-redux-i18n';
import Cards from 'components/PersonalChecksBanner/Cards/Cards';
import {
  FastCheckIcon,
  BadgeIcon,
} from 'components/PersonalChecksBanner/Icons/Icons';
import Companies from 'components/PersonalChecksBanner/Icons/Companies/Companies';
import {
  PersonalChecksFeaturesProps,
  PersonalChecksModalBodyHeader,
} from '../Features/Features';
import InfoMainSection from '../InfoMainSection/InfoMainSection';
import InfoMainHeader from '../InfoMainHeader/InfoMainHeader';
import styles from './InfoPage.module.scss';

const cards = [
  {
    title: 'components.PersonalChecks.modal.infoMainCards.faster.title',
    description:
      'components.PersonalChecks.modal.infoMainCards.faster.description',
    banner: FastCheckIcon,
  },
  {
    title: 'components.PersonalChecks.modal.infoMainCards.standOut.title',
    description:
      'components.PersonalChecks.modal.infoMainCards.standOut.description',
    banner: BadgeIcon,
  },
  {
    title: 'components.PersonalChecks.modal.infoMainCards.getNoticed.title',
    description:
      'components.PersonalChecks.modal.infoMainCards.getNoticed.description',
    banner: Companies,
  },
];

const InfoPageB = ({
  handleSubscribeClick,
  bannerTitle,
}: PersonalChecksFeaturesProps) => (
  <div className={styles.containerB}>
    <PersonalChecksModalBodyHeader bannerTitle={bannerTitle}>
      <M.Button
        type='button'
        onClick={handleSubscribeClick}
        id='personal-checks-modal-footer-subscribe-button'
        style={{
          display: 'flex',
          justifyContent: 'center',
          backgroundColor: '#cc2e78',
          border: '1px solid #cc2e78',
          height: '36px',
        }}
      >
        <T value='components.PersonalChecks.modal.subscribe' />
      </M.Button>
    </PersonalChecksModalBodyHeader>
    <InfoMainSection cards>
      <InfoMainHeader showDescription={false} />
      <Cards cards={cards} />
    </InfoMainSection>
  </div>
);

export default InfoPageB;
