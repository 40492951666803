import React from 'react';
import { Translate as T } from 'react-redux-i18n';
import { M } from '@dashboard-experience/mastodon';
import { uuid4 } from '@sentry/utils';
import './Features.scss';

export type FeatureProp = {
  title: string;
  description: string;
  isOpen?: boolean;
  eventKey: string;
};

export type FeaturesProps = {
  icon: string;
  title: string;
  features: FeatureProp[];
};

export type PersonalChecksFeaturesProps = {
  handleSubscribeClick?: () => void;
  bannerTitle?: React.ReactNode;
};

export const ExpandableFeature = ({
  title,
  description,
  isOpen = false,
  eventKey,
}: FeatureProp) => {
  return (
    <div className='personal-checks-modal-feature'>
      <div className='personal-checks-modal-feature-description'>
        <T value={description} dangerousHTML />
      </div>
    </div>
  );
};

export const PersonalChecksModalBodyHeader = ({
  children,
  bannerTitle,
}: {
  children: React.ReactNode;
  bannerTitle?: React.ReactNode;
}) => {
  return (
    <section id='personal-checks-modal-body-header'>
      <h3 id='personal-checks-modal-body-header-title'>{bannerTitle}</h3>
      <div id='personal-checks-modal-body-header-description'>
        <p id='personal-checks-modal-body-header-description-price'>
          <T
            value='components.PersonalChecks.modal.headerPrice'
            dangerousHTML
          />
        </p>
        {children}
      </div>
    </section>
  );
};

export function Features({ icon, title, features }: FeaturesProps) {
  return (
    <div id='personal-checks-modal-features-container'>
      <section id='personal-checks-modal-features-header'>
        <div id='personal-checks-modal-features-header-icon'>
          <M.Icon icon={icon} size='20' />
        </div>
        <p id='personal-checks-modal-features-header-title'>
          <T value={title} />
        </p>
      </section>
      <section id='personal-checks-modal-features-body'>
        {features.map(feature => (
          <ExpandableFeature
            key={uuid4()}
            title={feature.title}
            description={feature.description}
            isOpen={feature.isOpen || false}
            eventKey={feature.eventKey}
          />
        ))}
      </section>
    </div>
  );
}

export const InfoPageFeatures = () => (
  <>
    <h3 id='personal-checks-modal-body-features-title'>
      <T value='components.PersonalChecks.modal.featuresTitle' />
    </h3>
    <Features
      icon='User'
      title='components.PersonalChecks.modal.features.personalProfile'
      features={[
        {
          title: 'components.PersonalChecks.modal.features.personalProfile',
          description:
            'components.PersonalChecks.modal.features.personalProfileDescription',
          isOpen: true,
          eventKey: 'PERSONAL_CHECKS_PERSONAL_PROFILE_CLICKED',
        },
      ]}
    />
    <Features
      icon='Lightning'
      title='components.PersonalChecks.modal.features.preapproval'
      features={[
        {
          title: 'components.PersonalChecks.modal.features.preapproval',
          description:
            'components.PersonalChecks.modal.features.preapprovalDescription',
          isOpen: true,
          eventKey: 'PERSONAL_CHECKS_PRE_APPROVAL_CLICKED',
        },
      ]}
    />
  </>
);
