import React from 'react';
import './InfoModal.scss';
import InfoPageA from './InfoPage/InfoPageA';
import InfoPageB from './InfoPage/InfoPageB';
import PersonalChecksModalHeader from './Header/Header';

interface InfoModalProps {
  handleSubscribeClick: () => void;
  handleCloseClick: () => void;
  bannerTitle?: React.ReactNode;
  infoPageKey?: string;
}

const PersonalChecksModalBody = ({
  children,
}: {
  children: React.ReactNode;
}) => <div id='personal-checks-modal-body'>{children}</div>;

export default function InfoModal({
  handleCloseClick,
  handleSubscribeClick,
  bannerTitle,
  infoPageKey,
}: InfoModalProps) {
  let body;

  switch (infoPageKey) {
    case 'ideaA':
      body = (
        <InfoPageA
          handleSubscribeClick={handleSubscribeClick}
          bannerTitle={bannerTitle}
        />
      );
      break;
    case 'ideaB':
      body = (
        <InfoPageB
          handleSubscribeClick={handleSubscribeClick}
          bannerTitle={bannerTitle}
        />
      );
      break;
    default:
      body = (
        <InfoPageA
          handleSubscribeClick={handleSubscribeClick}
          bannerTitle={bannerTitle}
        />
      );
      break;
  }

  return (
    <>
      <PersonalChecksModalHeader handleCloseClick={handleCloseClick} />
      <PersonalChecksModalBody>{body}</PersonalChecksModalBody>
    </>
  );
}
