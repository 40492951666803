import React from 'react';
import {
  DominosIcon,
  OpenAIIcon,
  QualtricsIcon,
  SubwayIcon,
  UberIcon,
  DoorDashIcon,
} from '../Icons';
import styles from './Companies.module.scss';

export default function Companies() {
  return (
    <div className={styles['icon-container']}>
      <DominosIcon />
      <SubwayIcon />
      <DoorDashIcon />
      <OpenAIIcon />
      <UberIcon />
      <QualtricsIcon />
    </div>
  );
}
