import React from 'react';
import { Translate as T } from 'react-redux-i18n';
import styles from './InfoMainHeader.module.scss';

export default function InfoMainHeader({
  showDescription = true,
}: {
  showDescription?: boolean;
}) {
  return (
    <div className={styles.container}>
      <h3 className={styles.title}>
        <T value='components.PersonalChecks.modal.infoMainHeader.title' />
      </h3>
      {showDescription && (
        <p className={styles.description}>
          <T
            value='components.PersonalChecks.modal.infoMainHeader.description'
            dangerousHTML
          />
        </p>
      )}
    </div>
  );
}
