import React from 'react';

const InfoMainSection = ({
  cards,
  children,
}: {
  cards?: boolean;
  children: React.ReactNode;
}) => (
  <section id='personal-checks-modal-body-features'>
    <div id='personal-checks-modal-body-features-container'>{children}</div>
  </section>
);

export default InfoMainSection;
