import React from 'react';
import { Navigate, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { isInternational as getIsInternational } from 'lib/helpers';
import { getLoginSource } from 'containers/ReportsOverview/lib/getLoginSource';
import { ReportPage } from 'components/ReportPage/ReportPage';
import Auth from 'containers/Auth';
import { useReport } from 'api/report/useReport';
import useToken from 'utils/useToken';
import { useFlagr } from 'api/flagr';
import { AuthenticatedView } from '../ReportsOverview/AuthenticatedView';
import { FLAGR_KEYS, PATHS } from '../../constants';

const getReduxState = (state: any) => {
  const { portal, international } = state;
  return {
    portal,
    international:
      getIsInternational() || international?.international || false,
  };
};

type SingleReportParams = { reportId: string };

export const SingleReportContainer: React.FC = () => {
  const { international } = useSelector(getReduxState);
  const loginSource = getLoginSource(international);
  const { reportId } = useParams() as SingleReportParams;
  const token = useToken();
  const { report, isLoading, isError } = useReport({
    id: reportId,
    token,
  });

  // TODO: Remove this flag
  const {
    flag: { variantKey: newSsnResolutionFlow },
  } = useFlagr({
    context: {
      locationHost: window.location.host,
      candidateEmail: report?.candidate?.email,
    },
    id: FLAGR_KEYS.newSsnResolutionFlow,
    entity: { id: report?.candidate?.email, type: 'candidateEmail' },
    fallbackVariant: 'off',
  });

  if (isError)
    return <Navigate to={international ? PATHS.INTERNATIONAL : '/'} />;

  return (
    <Auth
      loginSource={loginSource}
      international={international}
      token={token}
      isLoading={isLoading || newSsnResolutionFlow === ''}
    >
      <AuthenticatedView>
        <ReportPage
          report={report}
          isLoading={isLoading}
          international={international}
        />
      </AuthenticatedView>
    </Auth>
  );
};

export default SingleReportContainer;
