import React from 'react';
import { M } from '@dashboard-experience/mastodon';
import { Translate as T } from 'react-redux-i18n';
import {
  InfoPageFeatures,
  PersonalChecksFeaturesProps,
  PersonalChecksModalBodyHeader,
} from '../Features/Features';
import InfoMainSection from '../InfoMainSection/InfoMainSection';
import InfoMainHeader from '../InfoMainHeader/InfoMainHeader';
import styles from './InfoPage.module.scss';

const InfoPageA = ({
  handleSubscribeClick,
  bannerTitle,
}: PersonalChecksFeaturesProps) => (
  <div className={styles.containerA}>
    <PersonalChecksModalBodyHeader bannerTitle={bannerTitle}>
      <M.Button
        type='button'
        onClick={handleSubscribeClick}
        id='personal-checks-modal-footer-subscribe-button'
        style={{
          display: 'flex',
          justifyContent: 'center',
          backgroundColor: '#cc2e78',
          border: '1px solid #cc2e78',
          height: '36px',
        }}
      >
        <T value='components.PersonalChecks.modal.subscribe' />
      </M.Button>
    </PersonalChecksModalBodyHeader>
    <InfoMainSection>
      <InfoMainHeader />
      <InfoPageFeatures />
    </InfoMainSection>
  </div>
);

export default InfoPageA;
