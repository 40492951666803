import React from 'react';
import { M } from '@dashboard-experience/mastodon';
import { Translate as T } from 'react-redux-i18n';
import styles from './BannerButtons.module.scss';

export default function BannerButtons({
  handleDetailsClick,
  personalChecksBannerKey,
}: {
  handleDetailsClick: () => void;
  personalChecksBannerKey: string;
}) {
  let buttonText;

  switch (personalChecksBannerKey) {
    case 'learnMore':
      buttonText = 'components.PersonalChecks.banner.buttons.learnMore';
      break;
    case 'subscribeFor999':
      buttonText = 'components.PersonalChecks.banner.buttons.subscribeFor999';
      break;
    default:
      buttonText = 'components.PersonalChecks.banner.buttons.learnMore';
      break;
  }

  return (
    <div className={styles['personal-checks-banner-buttons']}>
      {/* opens modal with details */}
      <M.Button
        onClick={handleDetailsClick}
        className={styles['personal-checks-banner-details-button']}
        style={{
          display: 'flex',
          justifyContent: 'center',
          backgroundColor: '#cc2e78',
          border: '1px solid #cc2e78',
          height: '36px',
        }}
      >
        <T value={buttonText} style={{ fontSize: '1rem' }} />
      </M.Button>
    </div>
  );
}
