import React from 'react';
import { Translate as T } from 'react-redux-i18n';
import styles from './BannerSubTitle.module.scss';

export default function BannerSubTitle() {
  return (
    <div className={styles.bannerSubtitle}>
      <T value='components.PersonalChecks.banner.subTitle' dangerousHTML />
    </div>
  );
}
