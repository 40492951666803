import React from 'react';
import Companies from 'components/PersonalChecksBanner/Icons/Companies/Companies';
import BannerTitle from '../BannerTitle/BannerTitle';
import BannerButtons from '../BannerButtons/BannerButtons';
import BannerSubTitle from '../BannerSubTitle/BannerSubTitle';
import styles from './Banners.module.scss';
import BannerBase from '../BannerBase/BannerBase';
import CloseBanner from '../BannerButtons/CloseBanner';

type BaseBannerProps = {
  bannerTitle: string | React.ReactNode;
  handleDetailsClick: () => void;
  personalChecksBannerKey: string;
  handleDismissClick: () => void;
};

export default function IconBanner({
  bannerTitle,
  handleDetailsClick,
  personalChecksBannerKey,
  handleDismissClick,
}: BaseBannerProps) {
  return (
    <BannerBase>
      <CloseBanner handleDismissClick={handleDismissClick} />
      <div className={styles.container}>
        <div className={styles.left}>
          <BannerTitle
            bannerTitle={bannerTitle}
            handleDetailsClick={handleDetailsClick}
          />
          <BannerSubTitle />
          <Companies />
        </div>
        <div className={styles.right}>
          <BannerButtons
            handleDetailsClick={handleDetailsClick}
            personalChecksBannerKey={personalChecksBannerKey}
          />
        </div>
      </div>
    </BannerBase>
  );
}
